import { useContext, useEffect, useRef, useState } from "react";
import { Autocomplete, Button, Center, Flex } from "@mantine/core";
import { BaseData } from "../../types/filters";
import { Panel } from "../../AppPage";
import { AppInfoContext } from "../contexts/AppInfoContext";
import ListElement from "./ListElement";
import Preloader from "../common/Preloader";
import styled from "@emotion/styled/macro";
import { IElementsList } from "../../types/common";
import { IconSearch } from "@tabler/icons-react";
import { filterListBySearchValue } from "../../common/filterListBySearchValue";

const ElementsList = <T extends BaseData>({
	name,
	list,
	filtersLoading,
	additionalClasses,
	iconsList,
	getElementSettings,
	initializeNewSystem,
	deleteSystem,
	duplicateSystem,
	reloadFilters,
	getSystemUsageInfo,
	setSettingsLoading,
}: IElementsList<T>) => {
	const { isSuperuser } = useContext(AppInfoContext);
	const ongoingAnimations = useRef<number>(0);
	const animationStartTime = useRef<CSSNumberish | null>(null);
	const [currentList, setCurrentList] = useState<T[]>(list);
	const [searchValue, setSearchValue] = useState<string | null>(null);

	useEffect(() => {
		setCurrentList(list);
	}, [list]);

	const handleDeleteSystem = async (settingsId: number) => {
		const res = await deleteSystem(settingsId);
		if (res) {
			ongoingAnimations.current -= 1;
			setCurrentList((prev) => prev.filter((element) => element.id !== settingsId));
			if (ongoingAnimations.current === 0) {
				animationStartTime.current = null;
				reloadFilters();
			}
		}
		return res;
	};

	const handleDuplicateSystem = async (settingsId: number) => {
		const res = await duplicateSystem(settingsId);
		if (res) reloadFilters();
		return res;
	};

	return (
		<PanelContainer className={additionalClasses}>
			{!filtersLoading ? (
				<>
					{name.length > 0 ? <h3 style={{ height: "fit-content" }}>{name}</h3> : null}

					<AutocompleteWithoutSuggestions
						label=""
						placeholder="Search"
						data={currentList.map((element) => element.name || element.specific_name || element.type_name || "unknown")}
						onChange={(value) => {
							setSearchValue(value);
						}}
						mb={"1rem"}
						limit={0}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								e.currentTarget.blur();
							}
						}}
					/>

					<ListWrapper style={{ position: "relative" }}>
						{filterListBySearchValue(currentList, searchValue).map((element) => (
							<ListElement
								name={element.name || element.specific_name || element.type_name || "unknown"}
								id={element.id}
								side={element.side !== undefined ? element.side : 2}
								key={element.id}
								getElementSettings={async () => {
									await getElementSettings(element.id);
								}}
								deleteSystem={handleDeleteSystem}
								duplicateSystem={handleDuplicateSystem}
								incrementOngoingAnimations={(startTime: CSSNumberish | null) => {
									ongoingAnimations.current += 1;
									if (ongoingAnimations.current === 1) animationStartTime.current = startTime || null;
									return animationStartTime.current;
								}}
								iconsList={iconsList}
								getSystemUsageInfo={getSystemUsageInfo}
							></ListElement>
						))}
					</ListWrapper>

					{isSuperuser ? (
						<Center pt={"1rem"}>
							<Button onClick={() => initializeNewSystem()} w={"fit-content"}>
								Add new
							</Button>
						</Center>
					) : null}
				</>
			) : (
				<Preloader></Preloader>
			)}
		</PanelContainer>
	);
};

const PanelContainer = styled(Panel)`
	overflow-y: auto;
	scrollbar-gutter: auto;
	height: fit-content;
	margin-top: 1rem;
`;

const ListWrapper = styled(Flex)`
	flex-direction: column;
	overflow-y: auto;

	@media (max-width: 768px) {
	}
`;

export const AutocompleteWithoutSuggestions = styled(Autocomplete)`
	& .mantine-Autocomplete-dropdown {
		display: none;
	}

	& .mantine-Autocomplete-input {
		border: 1px solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.light[4])} !important;
		border-radius: 0.25rem;
	}

	// margin-top: 2px;
`;

export default ElementsList;
