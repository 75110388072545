import { BaseData } from "../types/filters";

export const filterListBySearchValue = (list: BaseData[], searchValue: string | null) => {
	return list.filter((element) => {
		if (searchValue === null) return true;
		return (
			element.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
			element.specific_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
			element.type_name?.toLowerCase().includes(searchValue.toLowerCase())
		);
	});
};
