import { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, Button, Group, Flex, Overlay, Box, useMantineTheme, createStyles, Drawer, ActionIcon } from "@mantine/core";
import VoronoisLayer from "./layers/VoronoisLayer";
import Preloader from "../../../../components/common/Preloader";
import {
	AssessmentScenarioData,
	BehaviorParameter,
	BehaviorParameterTemplate,
	Echelon,
	InputData,
	ListForcePackageData,
	Optional,
	ParentEchelon,
} from "../../../../types/filters";
import RadiusLayer from "./layers/RadiusLayer";
import BaseLayer from "./layers/BaseLayer";
import PathLayer from "./layers/PathLayer";
import { downloadForcePackageSettings } from "../../../ForcePackages/downloadForcePackageSettings";
import BehaviorNestedAccordions from "./force-package-accordions/BehaviorNestedAccordions";
import { getRandomPositionFromCartesians } from "./functions/getRandomPositionFromCartesians";
import Symbol from "milsymbol";
import BehaviorPanel from "./force-package-accordions/BehaviorPanel";
import BehaviorEditiorContextProvider from "./contexts/BehaviorEditiorContext";
import * as Cesium from "cesium";
import { ImageryLayer, Viewer } from "resium";
import { EchelonsLayer } from "./layers/EchelonsLayer";
import { IconBrandGraphql } from "@tabler/icons-react";
import { replaceAt } from "../../../../common/replaceAt";
import {
	getPlanningAction,
	getBehaviorName,
	getBehaviorTemplate,
	getCurrentQueueProperty,
	getDependsOnProperty,
	getNextQueueEntry,
	getQueueEntry,
} from "./functions/behaviorSettingsFunctions";

interface IBehaviorsEditor {
	title: string;
	buttonText: string;
	geography: string;
	resolution: string;
	scenarioSettings: Optional<AssessmentScenarioData, "id">;
	disabled: boolean;
	defaultValue: string | string[] | number | number[];
	voronoisDataSource: Cesium.GeoJsonDataSource | null | undefined;
	elementOnWhichInputDepends?: BehaviorParameter;
	behaviorSettingsProperty: "blue_behavior_settings" | "red_behavior_settings";
	echelonSettingsProperty: "blue_echelon_settings" | "red_echelon_settings";
	inputData: InputData;
	onChange: (value: any) => void;
	setSetting: (settingName: string, value: AssessmentScenarioData[keyof AssessmentScenarioData]) => void;
}

const BehaviorsEditor: React.FC<IBehaviorsEditor> = ({
	title,
	buttonText,
	geography,
	resolution,
	defaultValue,
	scenarioSettings,
	disabled,
	voronoisDataSource,
	elementOnWhichInputDepends,
	behaviorSettingsProperty,
	echelonSettingsProperty,
	inputData,
	onChange,
	setSetting,
}) => {
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const [forcePackage, setForcePackage] = useState<null | ParentEchelon>(null);
	const [systemsIds, setSystemsIds] = useState<{ agent_id: number; system_id: number; is_commander: boolean }[]>();
	const [isOpened, setIsOpened] = useState(false);
	const [viewer, setViewer] = useState<Cesium.Viewer | null>(null);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [isBaseLayerLoaded, setIsBaseLayerLoaded] = useState(false);
	const [symbolsCreated, setSymbolsCreated] = useState(false);
	const [currentArea, setCurrentArea] = useState<(Cesium.Entity & { properties: Record<string, any> }) | null>(null);

	const [openedAccordionsNames, setOpenedAccordionsNames] = useState<string[]>([]);
	const [accordionsOpened, setAccordionsOpened] = useState(false);
	const [currentSymbolId, setCurrentSymbolId] = useState<number | null>(null);
	const [currentEchelonId, setCurrentEchelonId] = useState<number | null>(null);

	const [layerControls, setLayerControls] = useState<JSX.Element | null>(null); // controls when in edition mode
	const [showEchelonsLayer, setShowEchelonsLayer] = useState(false);

	const topLevelEchelonSettings = useMemo<BehaviorParameterTemplate[]>(() => {
		return [
			{ property: "ACTIVATION_DELAY", type: "double", default: 0 },
			{ property: "DEPLOYMENT_ZONE", type: "deploymentZone", default: -1 },
		];
	}, []);

	const key = "BsReTGnzAnujFxiYZywd";

	const updateIsOpened = useCallback(
		(value: boolean) => {
			if (!value) {
				setIsBaseLayerLoaded(false);
				viewer?.dataSources.removeAll();
				setCurrentSymbolId(null);
				setCurrentEchelonId(null);
			}
			setIsOpened(value);
		},
		[viewer]
	);

	const setEchelonSetting = (value: Record<number, BehaviorParameter[]> | null, propertyName: string) => {
		if (value) setSetting(propertyName, value);
	};

	const createSymbol = useCallback(
		(system: { system_id: number; agent_id: number; is_commander: boolean }) => {
			if (viewer) {
				const exists = viewer.entities.getById("symbol" + system.agent_id);

				if (!exists && currentArea?.polygon?.hierarchy) {
					const systemSettings = scenarioSettings.systems[system.system_id];
					const positions: Cesium.Cartesian3[] = currentArea?.polygon?.hierarchy.getValue(new Cesium.JulianDate(1, 1)).positions;
					const areaCenter = getRandomPositionFromCartesians(positions);

					viewer.entities.add({
						id: "symbol" + system.agent_id,
						position: Cesium.Cartesian3.fromRadians(areaCenter.longitude, areaCenter.latitude, areaCenter.height),
						properties: {
							is_commander: system.is_commander,
						},
						billboard: {
							image: new Symbol.Symbol(
								replaceAt(systemSettings.symbol, 1, behaviorSettingsProperty === "blue_behavior_settings" ? "F" : "H"),
								{
									size: 24,
								}
							).asCanvas(),
							eyeOffset: new Cesium.Cartesian3(0, 0, -200),
						},
					});
				}
			}
		},
		[viewer, currentArea, scenarioSettings]
	);

	const getLayer = useCallback(
		(
			key: string,
			defaultValue: number,
			systemSettings: ListForcePackageData & {
				cost: string | number;
				symbol: string;
			},
			system: { agent_id: number; system_id: number; is_commander: boolean },
			isPreview: boolean,
			dependsOn?: BehaviorParameter,
			queueInputIndex?: number
		): Record<string, JSX.Element | null> => {
			return {
				mapNode: viewer ? (
					<VoronoisLayer
						key={key}
						viewer={viewer}
						defaultValue={defaultValue || -1}
						systemSettings={systemSettings}
						systemIds={system}
						onChange={() => {}}
						previewMode={isPreview}
						isEditionModeOn={false}
						queueInputIndex={queueInputIndex}
						elementOnWhichInputDepends={dependsOn}
					/>
				) : null,
				mapRadius: viewer ? (
					<RadiusLayer
						key={key}
						viewer={viewer}
						defaultValue={defaultValue | 0}
						systemSettings={systemSettings}
						voronoisDataSource={voronoisDataSource}
						onChange={() => {}}
						systemIds={system}
						previewMode={isPreview}
						isEditionModeOn={false}
						queueInputIndex={queueInputIndex}
						elementOnWhichInputDepends={dependsOn}
					/>
				) : null,
				mapPath: viewer ? (
					<PathLayer
						key={key}
						viewer={viewer}
						defaultValue={defaultValue || []}
						systemSettings={systemSettings}
						systemIds={system}
						onChange={() => {}}
						previewMode={isPreview}
						isEditionModeOn={false}
						queueInputIndex={queueInputIndex}
						elementOnWhichInputDepends={dependsOn}
					/>
				) : null,
			};
		},
		[viewer, voronoisDataSource]
	);

	const updateCurrentArea = useCallback(() => {
		if (viewer && isBaseLayerLoaded) {
			const areas = viewer.dataSources?.getByName("areas")[0]?.entities?.values as (Cesium.Entity & {
				properties: Record<string, any>;
			})[];

			if (areas) {
				const currentArea = areas.find((x) => x.properties.type._value.toLowerCase().includes("staging")) || null;
				setCurrentArea(currentArea);
			}
		}
	}, [viewer, isBaseLayerLoaded]);

	const getPreviewLayers = useCallback(
		(
			systemIx: string,
			system: { agent_id: number; system_id: number; is_commander: boolean },
			template: BehaviorParameterTemplate[],
			planningActions: BehaviorParameter,
			queueInputIndex?: number,
			elementOnWhichInputDepends?: BehaviorParameter // if called by getQueueLayersRecursively
		) => {
			const getQueueLayersRecursively = (
				entry: BehaviorParameter | undefined,
				depth: number,
				elementOnWhichInputDepends?: BehaviorParameter
			): (JSX.Element | null)[] => {
				if (entry) {
					const planningActions = getPlanningAction(entry?.settings);
					const planningActionName = getBehaviorName(planningActions);
					const template = getBehaviorTemplate(inputData, planningActionName);

					if (template) {
						const currentLayers =
							planningActions && template
								? getPreviewLayers(
										`${systemIx}${depth}`,
										system,
										template,
										planningActions,
										depth + 1,
										elementOnWhichInputDepends
								  )
								: [];

						const layers: (JSX.Element | null)[] = [
							...currentLayers,
							...getQueueLayersRecursively(getNextQueueEntry(entry), depth + 1, getCurrentQueueProperty(entry)),
						];
						return layers;
					}
				}
				return [];
			};

			const systemSettings = scenarioSettings.systems[system.system_id];

			const layers = (planningActions.settings as BehaviorParameter[]).map((setting, settingIx) => {
				const inputTemplate = template.find((template) => template.property === setting.property) as BehaviorParameterTemplate;
				const dependsOn = getDependsOnProperty(planningActions, inputTemplate?.dependsOn) || elementOnWhichInputDepends;

				if (inputTemplate) {
					if (inputTemplate.type === "actionQueue") {
						return getQueueLayersRecursively(getQueueEntry(setting), 0);
					} else {
						return getLayer(
							systemIx + inputTemplate.property + settingIx,
							setting.value as number,
							systemSettings,
							system,
							true,
							dependsOn,
							queueInputIndex
						)[inputTemplate.type];
					}
				} else {
					return null;
				}
			});

			return layers.flat();
		},
		[scenarioSettings, getLayer, inputData]
	);

	useEffect(() => {
		updateCurrentArea();
	}, [updateCurrentArea]);

	// get list of all systems ids
	useEffect(() => {
		const getSystemsIds = (parentEchelon: Echelon): { agent_id: number; system_id: number; is_commander: boolean }[] => {
			const systems: { agent_id: number; system_id: number; is_commander: boolean }[] = [];

			if (parentEchelon.systems) {
				parentEchelon.systems.forEach((system) => {
					systems.push({ ...system, is_commander: parentEchelon.type !== 1 });
				});
			}

			if (parentEchelon.echelons) {
				parentEchelon.echelons.forEach((echelon) => {
					systems.push(...getSystemsIds(echelon));
				});
			}

			return systems;
		};

		if (!systemsIds && forcePackage) {
			const systems = getSystemsIds(forcePackage.echelons[0]);
			setSystemsIds(systems);
		}
	}, [systemsIds, forcePackage]);

	// Download force package settings
	useEffect(() => {
		const downloadFp = async () => {
			const forcePackage = await downloadForcePackageSettings(
				scenarioSettings[
					behaviorSettingsProperty === "blue_behavior_settings" ? "blue_force_package" : "red_force_package"
				] as number
			);

			if (forcePackage) {
				setForcePackage(forcePackage.fp as ParentEchelon);
			}
		};

		if (!forcePackage) downloadFp();
	}, [scenarioSettings, behaviorSettingsProperty, forcePackage]);

	// Set events for system selection
	useEffect(() => {
		const setSelectedSymbolId = (selectedEntity: Cesium.Entity) => {
			if (selectedEntity && viewer) {
				const entity = viewer.entities.values.find((x) => x.id === selectedEntity.id);
				if (entity) {
					if (selectedEntity.id.includes("echelon")) {
						setCurrentEchelonId(parseInt(entity.id.split("echelon")[1]));
						setCurrentSymbolId(null);
					} else {
						setCurrentSymbolId(parseInt(entity.id.split("symbol")[1]));
						setCurrentEchelonId(null);
					}
				}
			} else {
				setCurrentSymbolId(null);
				setCurrentEchelonId(null);
			}
		};

		viewer && viewer.selectedEntityChanged.addEventListener(setSelectedSymbolId);

		return () => {
			viewer && viewer.selectedEntityChanged.removeEventListener(setSelectedSymbolId);
		};
	}, [viewer]);

	// Select symbol on system click in fp
	useEffect(() => {
		if (viewer && currentSymbolId !== null) {
			const entity = viewer.entities.values.find((x) => x.id === "symbol" + currentSymbolId);
			viewer.selectedEntity = entity;
			setAccordionsOpened(false);
		}

		if (currentSymbolId === null) {
			setLayerControls(null);
		}
	}, [currentSymbolId, viewer]);

	// Select echelon on system click in fp
	useEffect(() => {
		if (viewer && currentEchelonId !== null) {
			const entity = viewer.entities.values.find((x) => x.id === "echelon" + currentEchelonId);
			viewer.selectedEntity = entity;
			setAccordionsOpened(false);
		}

		if (currentEchelonId === null) {
			setLayerControls(null);
		}
	}, [currentEchelonId, viewer]);

	return (
		<BehaviorEditiorContextProvider viewer={viewer}>
			{isOpened && systemsIds && systemsIds.length > 0 ? (
				<Modal
					className={classes.modal}
					opened={isOpened}
					onClose={() => updateIsOpened(false)}
					title={title}
					size={"90vw"}
					fullScreen
				>
					<Flex h={"80vh"}>
						<Viewer
							full
							ref={(e) => {
								if (e && e.cesiumElement) {
									setViewer(e.cesiumElement);
								}
							}}
							animation={false}
							timeline={false}
							baseLayerPicker={false}
							navigationHelpButton={false}
							sceneModePicker={false}
							homeButton={false}
							geocoder={false}
							fullscreenButton={false}
						>
							<ImageryLayer
								imageryProvider={
									new Cesium.UrlTemplateImageryProvider({
										url: `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${key}`,
										minimumLevel: 0,
										maximumLevel: 20,
										credit: new Cesium.Credit(
											'\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
											true
										),
									})
								}
							/>

							<BaseLayer
								viewer={viewer as Cesium.Viewer}
								geography={geography}
								resolution={resolution}
								side={behaviorSettingsProperty === "blue_behavior_settings" ? 0 : 1}
								voronoisDataSource={voronoisDataSource}
								elementOnWhichInputDepends={elementOnWhichInputDepends}
								setIsDataLoading={setIsDataLoading}
								setIsBaseLayerLoaded={setIsBaseLayerLoaded}
							/>

							{isBaseLayerLoaded && currentArea && !layerControls
								? systemsIds.map((system, ix) => {
										createSymbol(system);
										if (ix === systemsIds.length - 1 && !symbolsCreated) setSymbolsCreated(true);

										const planningActions = scenarioSettings[behaviorSettingsProperty][system.agent_id]?.find(
											(behavior) => behavior.property === "PLANNING_ACTION"
										);
										const planningActionName = getBehaviorName(planningActions);
										const template = getBehaviorTemplate(inputData, planningActionName);

										if (template && planningActions && planningActions.settings && viewer) {
											return getPreviewLayers(ix.toString(), system, template, planningActions);
										}
										return null;
								  })
								: null}

							{forcePackage && viewer && isBaseLayerLoaded && symbolsCreated ? (
								<EchelonsLayer
									viewer={viewer as Cesium.Viewer}
									forcePackage={forcePackage}
									side={behaviorSettingsProperty === "blue_behavior_settings" ? 0 : 1}
									visible={showEchelonsLayer}
								></EchelonsLayer>
							) : null}

							{/* System and echelons behaviors panel */}
							{(currentSymbolId !== null || currentEchelonId !== null) && viewer ? (
								<Box
									style={{
										display: "flex",
										position: "absolute",
										height: "fit-content",
										maxHeight: "calc(100% - 3rem - 20px)",
										overflowY: "auto",
										padding: "0 1rem 1rem 1rem",
										top: "calc(3rem + 10px)",
										left: 10,
										borderRadius: "0.25rem",
										backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
									}}
								>
									{currentSymbolId !== null && systemsIds.find((system) => system.agent_id === currentSymbolId) ? (
										<Flex style={{ position: "relative" }}>
											<BehaviorPanel
												key={currentSymbolId}
												cesiumViewer={viewer as Cesium.Viewer}
												entityId={currentSymbolId}
												behaviorSettings={scenarioSettings[behaviorSettingsProperty]}
												inputData={inputData}
												scenarioSettings={scenarioSettings}
												setSetting={(value) => setEchelonSetting(value, behaviorSettingsProperty)}
												systemId={
													systemsIds.find((system) => system.agent_id === currentSymbolId)?.system_id as number
												}
												isCommander={
													systemsIds.find((system) => system.agent_id === currentSymbolId)
														?.is_commander as boolean
												}
												isEditionModeOn={layerControls !== null}
												setLayerControls={(value: JSX.Element | null) => setLayerControls(value)}
											></BehaviorPanel>
										</Flex>
									) : currentEchelonId !== null ? (
										<Flex style={{ position: "relative" }}>
											<BehaviorPanel
												key={currentEchelonId}
												cesiumViewer={viewer as Cesium.Viewer}
												entityId={currentEchelonId}
												behaviorSettings={scenarioSettings[echelonSettingsProperty]}
												inputData={inputData}
												scenarioSettings={scenarioSettings}
												setSetting={(value) => setEchelonSetting(value, echelonSettingsProperty)}
												systemId={currentEchelonId}
												isCommander={false}
												isEditionModeOn={layerControls !== null}
												setLayerControls={(value: JSX.Element | null) => setLayerControls(value)}
												topLevelSettings={topLevelEchelonSettings}
												isEchelonLevel
											></BehaviorPanel>
										</Flex>
									) : null}
								</Box>
							) : null}

							<Drawer
								className={classes.drawer}
								opened={accordionsOpened}
								onClose={() => setAccordionsOpened(false)}
								w={"40%"}
							>
								<Box
									style={{
										width: "100%",
										position: "relative",
										padding: "1rem",
										backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
									}}
								>
									{forcePackage ? (
										<BehaviorNestedAccordions
											forcePackage={forcePackage}
											scenarioSettings={scenarioSettings}
											isTopLevel={true}
											setSetting={setSetting}
											pathToElement={[]}
											behaviorSettingsProperty={behaviorSettingsProperty}
											echelonSettingsProperty={echelonSettingsProperty}
											inputData={inputData}
											updateSelectedSymbolId={(id: number | null) => setCurrentSymbolId(id)}
											updateSelectedEchelonId={(id: number | null) => setCurrentEchelonId(id)}
											openedAccordionsNames={openedAccordionsNames}
											setOpenedAccordionsNames={(value: string[]) => setOpenedAccordionsNames(value)}
											topLevelSettings={topLevelEchelonSettings}
										></BehaviorNestedAccordions>
									) : (
										<Preloader />
									)}
								</Box>
							</Drawer>

							<Flex
								style={{
									position: "absolute",
									top: "calc(3rem + 10px)",
									right: 10,
									borderRadius: "0.25rem",
									flexDirection: "row",
								}}
							>
								<ActionIcon
									variant="filled"
									title="Toggle echelons visibility"
									color={showEchelonsLayer ? "green.5" : "gray.5"}
									bg={showEchelonsLayer ? "green" : "gray"}
									w={"2.25rem"}
									h={"2.25rem"}
									mr={"0.5rem"}
									onClick={() => setShowEchelonsLayer((prev) => !prev)}
								>
									<IconBrandGraphql size="1.125rem" />
								</ActionIcon>

								<Button
									style={{
										backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[2],
									}}
									onClick={() => setAccordionsOpened(true)}
								>
									Open Package
								</Button>
							</Flex>

							{isDataLoading || !isBaseLayerLoaded ? (
								<Overlay style={{ display: "flex" }}>
									<Preloader />
								</Overlay>
							) : null}

							{layerControls}
						</Viewer>
					</Flex>
				</Modal>
			) : null}

			<Group position="center">
				<Button w={"100%"} onClick={() => updateIsOpened(true)} disabled={disabled} component="div" variant={"filled"}>
					{/* component="div" is needed to prevent html from throwing wrong nesting error if used inside Accordion.Control */}
					{buttonText}
				</Button>
			</Group>
		</BehaviorEditiorContextProvider>
	);
};

const useStyles = createStyles((theme, _params, _variations) => ({
	modal: {
		".mantine-Modal-header": {
			height: "3rem",
		},
	},
	drawer: {
		".mantine-Drawer-content": {
			flex: "0 1 auto",
			width: "40vw",
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
		},
		".mantine-Drawer-header": {
			height: "3rem",
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
		},
		".mantine-Drawer-body": {
			padding: 0,
		},
	},
}));

export default BehaviorsEditor;
