import { Anchor, Button, Flex, Table, Text, px, useMantineTheme } from "@mantine/core";
import styled from "@emotion/styled/macro";
import Preloader from "../../../components/common/Preloader";
import { Panel } from "../../../AppPage";
import { useCallback, useEffect, useMemo, useState } from "react";
import makeRequest from "../../../common/makeRequest";
import { useParams } from "react-router-dom";
import { ForcePackageGraphData } from "../../../types/filters";
import { toast } from "react-toastify";
import Tree, { CustomNodeElementProps, RawNodeDatum } from "react-d3-tree";
import CustomGraphNode from "./CustomGraphNode";

const ForcePackagesGraphPage: React.FC = () => {
	const theme = useMantineTheme();
	const [graphData, setGraphData] = useState<RawNodeDatum>();
	const [statsData, setStatsData] = useState<Record<number, { count: number; name: string }>>();
	const [totalCount, setTotalCount] = useState<number>(0);
	const [totalCost, setTotalCost] = useState<string>("0.00");
	const [fpName, setFpName] = useState<string>("");
	const [translate, containerRef] = useCenteredTree();
	const nodeSize = useMemo(() => ({ x: 200, y: 120 }), []);
	let { fpId } = useParams();

	const updateElementChildren = useCallback((data: RawNodeDatum, name: string, newChild: RawNodeDatum): boolean => {
		if (data.name === name) {
			if (!data.children) {
				data.children = [];
			}
			data.children.push(newChild);
			return true;
		}

		if (data.children) {
			for (const child of data.children) {
				const hasUpdated = updateElementChildren(child, name, newChild);
				if (hasUpdated) {
					return true;
				}
			}
		}

		return false;
	}, []);

	const formatData = useCallback(
		(data: ForcePackageGraphData): RawNodeDatum => {
			const isRedPackage = data.fp_orbat[0].properties.name.toUpperCase().includes("RED");
			const formattedData: RawNodeDatum = {
				name: data.fp_orbat[0].properties.name,
				attributes: { symbol: data.fp_orbat[0].properties.SIDC, isRoot: "true", isRedPackage: isRedPackage },
				children: [],
			};

			for (let i = 1; i < data.fp_orbat.length; i++) {
				const { name, SIDC, command } = data.fp_orbat[i].properties;

				if (command) {
					updateElementChildren(formattedData, command, {
						name: name,
						attributes: { symbol: SIDC, isRoot: "false", isRedPackage: isRedPackage },
						children: [],
					});
				}
			}

			return formattedData as RawNodeDatum;
		},
		[updateElementChildren]
	);

	const downloadSpreadsheet = async () => {
		const res = await makeRequest(`force_packages/${fpId}/download_fp_systems`, "GET");
		if (res.ok) {
			const blob = await res.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.click();
			a.remove();
		} else {
			toast.error("Error downloading spreadsheet");
		}
	};

	useEffect(() => {
		const downloadGraph = async () => {
			const res = await makeRequest(`force_packages/${fpId}/get_orbat`, "GET");
			if (res.ok) {
				const data = (await res.json()) as ForcePackageGraphData;
				setStatsData(data.fp_systems_count);
				setTotalCount(Object.values(data.fp_systems_count).reduce((acc, curr) => acc + curr.count, 0));
				setTotalCost(data.fp_cost);
				setFpName(data.fp_name);
				const formattedData = formatData(data);
				setGraphData(formattedData);
			} else {
				toast.error("Error downloading graph data");
			}
		};

		downloadGraph();
	}, [fpId, formatData]);

	return (
		<PageContainer>
			<GraphWrapper ref={containerRef as any}>
				<FullHeightPanel>
					<Flex style={{ justifyContent: "center" }}>
						<Text weight={"bold"} c={theme.colors.dark[2]}>
							{fpName}
						</Text>
					</Flex>
					{graphData ? (
						<Tree
							initialDepth={1}
							depthFactor={250}
							separation={{ siblings: 1, nonSiblings: 0.5 }}
							orientation="vertical"
							pathFunc={"step"}
							pathClassFunc={() => "link"}
							rootNodeClassName="node"
							branchNodeClassName="node"
							leafNodeClassName="node"
							translate={translate as { x: number; y: number }}
							nodeSize={nodeSize}
							zoom={0.7}
							collapsible={true}
							shouldCollapseNeighborNodes
							data={graphData}
							renderCustomNodeElement={(props: CustomNodeElementProps) => {
								const name = props.nodeDatum.name;
								return (
									<CustomGraphNode
										name={name.substring(0, name.length - 12)}
										symbolCode={(props.nodeDatum.attributes as Record<string, string>).symbol as string}
										isRoot={(props.nodeDatum.attributes as Record<string, string>).isRoot === "true"}
										nodeSize={nodeSize}
										toggleNode={props.toggleNode}
										isRedPackage={(props.nodeDatum.attributes as Record<string, any>).isRedPackage === true}
									></CustomGraphNode>
								);
							}}
						/>
					) : (
						<Preloader></Preloader>
					)}
				</FullHeightPanel>
			</GraphWrapper>

			<StatsWrapper>
				<Panel>
					{statsData ? (
						<>
							<Button mb={"1rem"} pt={"0.5rem"} pb={"0.5rem"} onClick={downloadSpreadsheet}>
								Download spreadsheet
							</Button>
							<StyledTable>
								<thead>
									<tr>
										<th>System</th>
										<th>Count</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(statsData).map(([key, value]) => (
										<tr key={key}>
											<td>
												<Anchor href={`${window.location.origin}/systems-library/systems/${key}`} target="_blank">
													{value.name}
												</Anchor>
											</td>
											<td>{value.count}</td>
										</tr>
									))}
									<tr>
										<td>Total</td>
										<td>{totalCount}</td>
									</tr>
									<tr>
										<td>Total cost</td>
										<td>{totalCost}</td>
									</tr>
								</tbody>
							</StyledTable>
						</>
					) : (
						<Preloader></Preloader>
					)}
				</Panel>
			</StatsWrapper>
		</PageContainer>
	);
};

export const useCenteredTree = () => {
	const [translate, setTranslate] = useState({ x: 0, y: 0 });

	const containerRef = useCallback((containerElem: HTMLDivElement) => {
		if (containerElem !== null) {
			const { width, height } = containerElem.getBoundingClientRect();
			// Panel padding is 2 rem per side
			setTranslate({ x: (width - px("4rem")) / 2, y: (height - px("4rem")) / 2 - 50 });
		}
	}, []);

	return [translate, containerRef];
};

export const PageContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	height: calc(100vh - var(--header-height) - var(--footer-height) - var(--content-padding) * 2 - var(--breadcrumbs-height));
	max-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--content-padding) * 2 - var(--breadcrumbs-height));

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const GraphWrapper = styled(Flex)`
	flex-direction: column;
	width: 70%;
	max-height: 100%;
	overflow-y: auto;

	@media (max-width: 768px) {
		max-height: 50vh;
		width: 100%;
	}
`;

const FullHeightPanel = styled(Panel)`
	height: 100%;
`;

const StatsWrapper = styled(Flex)`
	flex-direction: column;
	width: 30%;
	max-height: 100%;
	overflow-y: auto;
	margin-left: 1rem;
	height: 100%;
	flex: 1;

	@media (max-width: 768px) {
		width: 100%;
		margin: 0;
		margin-top: 1rem;
	}

	& table {
		max-height: 100%;
	}

	& th {
		text-align: left;
	}

	& tr:last-of-type,
	tr:nth-last-of-type(2) {
		font-weight: bold;
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2])};
	}
`;

const StyledTable = styled(Table)`
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};

	& th {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2])} !important;
	}
`;

export default ForcePackagesGraphPage;
